<template>
  <v-dialog :value="value" height="700px" width="700px" persistent @input="$emit('input', false)">
    <v-card>
      <v-card-title>
        <p class="block-title">
          Contatos
        </p>
      </v-card-title>
      <v-card-text>
        <v-data-table :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }" :headers="headers" :items="items"> </v-data-table>
      </v-card-text>
      <v-card-actions class="mt-n2 pr-5 pb-5 justify-end">
        <v-btn class="px-5" outlined depressed @click="$emit('input', false)"> fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ContactsDetailsDialog',

  props: {
    itemSelected: {
      type: Object,
      required: true,
      default: () => {}
    },
    value: Boolean
  },

  computed: {
    items() {
      return this.itemSelected.contacts || []
    },
    headers() {
      return [
        {
          text: 'Responsável',
          value: 'owner',
          align: 'center',
          width: '33%'
        },
        {
          text: 'Email',
          value: 'email',
          align: 'center',
          width: '33%'
        },
        {
          text: 'Contato',
          value: 'phone',
          align: 'center',
          width: '33%'
        }
      ]
    }
  }
}
</script>

<style></style>
